import Home from "./pages/Home";
import { ThemeProvider } from "@emotion/react";
import theme from "./components/General/theme";
import AppDrawer from "./components/General/AppDrawer/AppDrawer";
import { UIProvider } from "./context";
import RecivedPage from "./pages/RecivedPage";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <UIProvider>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/recived" element={<RecivedPage />} />
          </Routes>
          <AppDrawer />
        </div>
      </ThemeProvider>
    </UIProvider>
  );
}

export default App;
