import { Box, Button, Typography, styled } from "@mui/material";
import { Colors, FontsFamily } from "./theme";

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.primary,
  fontSize: "20px",
  textAlign: "end",
  paddingTop: "24px",
  paddingRight: "16px",

  [theme.breakpoints.up("sm")]: {
    paddingTop: "16px",
    paddingRight: "12px",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
    paddingTop: "20px",
    paddingRight: "96px",
  },
}));

export const SectionSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.secondary,
  fontSize: "12px",
  textAlign: "end",
  marginTop: "4px",
  paddingRight: "16px",
  [theme.breakpoints.up("sm")]: {
    paddingRight: "12px",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
    paddingRight: "96px",
  },
}));

export const Cover = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.primary,
  opacity: "80%",
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "32px",
  zIndex: 5,
}));

export const CoverText = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.primary,
  color: Colors.white,
  fontSize: "16px",
  width: "70%",
  textAlign: "center",
  lineHeight: 2,
}));

export const ClouseCover = styled(Button)(({ theme }) => ({
  backgroundColor: Colors.white,
  color: Colors.primary,
  fontFamily: FontsFamily.primary,
  width: "50%",
  border: `2px solid ${Colors.white}`,
}));
