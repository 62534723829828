import React from "react";
import {
  Container,
  BolitsPointIllustration,
  BolitsPointTitle,
  BolitsPointSubtitle,
  BolitsPointContainer,
  ElementContainer,
  ShapeIllustration,
  ShapeIllustration1,
  ShapeIllustration2,
  ShapeIllustration3,
} from "./BolitsPointsStyle";
import {
  SectionSubtitle,
  SectionTitle,
} from "../../General/GeneralElementsStyle";
import { Box, Divider } from "@mui/material";

export default function BoiltsPoints() {
  const bolitsPointDetailes = [
    {
      illustartionSoucrce: "/assets/illustartion1.svg",
      title: "نركز بشكل اساسي على تصميم موقع يحول الزوار الى عملا",
      subtitle:
        " الموقع الكتروني الناجح هو الذي  — يقدم الشركة بطريقة يكسب من خلالها ثقة العملاء. —يحول المتصفح الى عميل دائم لدى الشركة آو المؤسسة. و هذه هي المعايير التي نقيس نجاحنا عليها.,",
    },
    {
      illustartionSoucrce: "/assets/illustartion2.svg",
      title: "متخصصون في صنع المواقع للشركات و المؤسسات المتوسطة و الصغير",
      subtitle:
        "آوريجين متخصصة في مجال محدد لمعرفتها الكاملة بالطرق التي تضمن وصول النتائج للعميل",
    },
    {
      illustartionSoucrce: "/assets/illustartion3.svg",
      title: "القيمة الآولى لدينا هي الاهتمام بخدمة و رضا العملاء",
      subtitle:
        "آوريجين توفر خدمة عملاء مجانية و تعمل بكامل طاقتها لضمان رضا العميل في النتائج التي حصل عليها",
    },
  ];
  const display = bolitsPointDetailes.map((el, index) => (
    <ElementContainer key={index}>
      <BolitsPointIllustration src={el.illustartionSoucrce} />
      <BolitsPointTitle>{el.title}</BolitsPointTitle>
      <BolitsPointSubtitle>{el.subtitle}</BolitsPointSubtitle>
    </ElementContainer>
  ));

  return (
    <Container id="bolitPoints">
      <SectionTitle> .. بماذا نهتم </SectionTitle>
      <SectionSubtitle>القيم الأساسية في العمل و التصميم</SectionSubtitle>
      <BolitsPointContainer>{display}</BolitsPointContainer>

      {/* shapes */}
      <ShapeIllustration1 src={"/assets/bolitIllustration1.svg"} />
      <ShapeIllustration2 src={"/assets/bolitIllustration2.svg"} />
      <ShapeIllustration3 src={"/assets/bolitIllustration1.svg"} />
      <ShapeIllustration src={"/assets/bolitIllustration.svg"} />
    </Container>
  );
}
