import { Box, Typography, styled } from "@mui/material";
import { Colors, FontsFamily } from "../../General/theme";

export const Container = styled(Box)(({ theme }) => ({
  background: Colors.secondary,
  paddingBottom: "24px",
  position: "relative",

  [theme.breakpoints.up("sm")]: {
    paddingBottom: "88px",
    marginTop: "-60px",
  },

  [theme.breakpoints.up("md")]: {
    paddingBottom: "120px",
    marginTop: "0px",
  },
}));

export const ProductContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: "24px",
  gap: "32px",
  padding: "0 16px",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    gap: "56px",
    padding: "0 16px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "0 48px",
    gap: "120px",
  },
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  width: "262.8px",
  height: "316.4px",
  background: "gray",
  borderRadius: "22px",
  [theme.breakpoints.up("sm")]: {
    minWidth: "303px",
    minHeight: "361px",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: "415px",
    minHeight: "499px",
  },
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  textAlign: "end",

  [theme.breakpoints.up("sm")]: {},
  [theme.breakpoints.up("ms")]: {},
}));

export const ProductTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.primary,
  fontSize: "12px",
  marginBottom: "4px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
    marginBottom: "8px",
  },
  [theme.breakpoints.up("ms")]: {
    fontSize: "24px",
    marginBottom: "12px",
  },
}));

export const ProductSubitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.secondary,
  fontSize: "12px",
  marginBottom: "20px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
    marginBottom: "24px",
  },
  [theme.breakpoints.up("ms")]: {
    fontSize: "24px",
    marginBottom: "12px",
  },
}));

export const ToFlex = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.up("ms")]: {},
}));

export const UIContainer = styled("ul")(({ theme }) => ({
  fontFamily: FontsFamily.secondary,
  textAlign: "right",
  direction: "rtl",
  float: "right",
  fontSize: "12px",
  marginBottom: "0",
  marginTop: "0",
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.up("ms")]: {
    fontSize: "24px",
  },
}));

export const LiElement = styled("li")(({ theme }) => ({
  fontFamily: FontsFamily.secondary,
  fontSize: "12px",
  marginBottom: "8px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    marginBottom: "32px",
  },
  [theme.breakpoints.up("ms")]: {
    fontSize: "20px",
    marginBottom: "32px",
  },
}));

export const ArrowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "95%",
  position: "absolute",
  top: "40%",
  [theme.breakpoints.up("sm")]: {
    top: "90%",
    justifyContent: "center",
    gap: "32px",
  },
}));
