import React from "react";
import "./Carousle.css";

export function Carousle(props) {
  const items = [
    {
      src: "/assets/ourCustomer/c1.png",
      alt: "Customer Logo",
    },
    {
      src: "/assets/ourCustomer/c2.png",
      alt: "Customer Logo",
    },
    {
      src: "/assets/ourCustomer/c3.png",
      alt: "Customer Logo",
    },
    {
      src: "/assets/ourCustomer/c4.png",
      alt: "Customer Logo",
    },
    {
      src: "/assets/ourCustomer/c5.png",
      alt: "Customer Logo",
    },
    {
      src: "/assets/ourCustomer/c6.jpg",
      alt: "Customer Logo",
    },
  ];

  const displayImage = items.map((item, index) => {
    return <img key={index} src={item.src} alt={item.alt} />;
  });
  return (
    <div className="logo" style={{ margin: "32px 0 " }}>
      <div className="logo-slide">{displayImage}</div>
      <div className="logo-slide">{displayImage}</div>
      <div className="logo-slide">{displayImage}</div>
      <div className="logo-slide">{displayImage}</div>
    </div>
  );
}
