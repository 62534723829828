// it's importent to  import styled from "@mui/material/styles"
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { Colors, FontsFamily } from "../theme";

export const FooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "100%",
  height: "80px",
  background: "#212124",
  padding: "0px 24px",
  marginTop: "120px",

  [theme.breakpoints.up("sm")]: {
    height: "104px",
    marginTop: "104px",
  },
  [theme.breakpoints.up("md")]: {
    marginTop: "80px",
  },
}));

export const Logo = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "10%",
}));

export const LogoText = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.primary,
  fontSize: "16px",
  color: Colors.white,
}));
export const CopyRights = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.secondary,
  fontSize: "12px",
  color: Colors.white,
}));

export const MediaIconsDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
}));
