import { Box, useMediaQuery, useTheme } from "@mui/material";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  CopyRights,
  FooterContainer,
  LogoText,
  MediaIconsDiv,
} from "./FooterStyle";
import { Colors } from "../theme";

export default function Footer() {
  return (
    <FooterContainer>
      <LogoText>Origin Go</LogoText>
      <CopyRights>Cope Write to OrigianGo @2023</CopyRights>
      <MediaIconsDiv>
        <TwitterIcon
          sx={{ color: Colors.white, width: "16px", cursor: "pointer" }}
        />
        <FacebookIcon
          sx={{ color: Colors.white, width: "16px", cursor: "pointer" }}
        />
        <InstagramIcon
          sx={{ color: Colors.white, width: "16px", cursor: "pointer" }}
        />
      </MediaIconsDiv>
    </FooterContainer>
  );
}
