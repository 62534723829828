import React, { useState } from "react";
import {
  ArrowContainer,
  Container,
  ImageContainer,
  LiElement,
  ProductContainer,
  ProductSubitle,
  ProductTitle,
  TextContainer,
  ToFlex,
  UIContainer,
} from "./ProductsStyle";
import {
  ClouseCover,
  Cover,
  CoverText,
  SectionSubtitle,
  SectionTitle,
} from "../../General/GeneralElementsStyle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";

export default function Products() {
  const [show, UseShow] = useState(true);

  return (
    <Container id="products">
      <SectionTitle> .. أعمالنا</SectionTitle>
      <SectionSubtitle>: جزء من الاعمال السابقة</SectionSubtitle>
      <ProductContainer>
        <ImageContainer></ImageContainer>
        <TextContainer>
          <ProductTitle>
            هدفنا هو تقديم مواقع الكترونية موثوقة بجودة عالية للشركات و المؤسسات
            . المتوسطة و الصغيرة
          </ProductTitle>
          <ProductSubitle>
            رؤيتنا هي تقديم خدمة تساعدهم العملاء على النمو
          </ProductSubitle>
          <ToFlex>
            <UIContainer>
              <LiElement>
                تصميم الموقع بطريقة علمية و ليس استنساخ الاخرين.
              </LiElement>
              <LiElement>السرعة في الأداء مع الحفاظ على الجودة.</LiElement>
              <LiElement>
                آوريجين متخصصة في مجال محدد لمعرفتها الكاملة.{" "}
              </LiElement>
            </UIContainer>
            <UIContainer>
              <LiElement>
                بناء رحلة عميل كاملة من تصميم و تنفيذ الموقع
              </LiElement>
              <LiElement>تقديم خدمات ال SEO و التسويق الالكتروني</LiElement>
              <LiElement>التخصص و التركيز على مساعدة فئه معينة.</LiElement>
            </UIContainer>
          </ToFlex>
        </TextContainer>
        <ArrowContainer>
          <ArrowBackIcon
            sx={{
              color: "#DBE8AF",
              padding: "4px 8px",
              background: "#FFF",
              borderRadius: "8px",
              cursor: "not-allowed",
            }}
          />
          <ArrowForwardIcon
            sx={{
              color: "rgba(99, 157, 74, 1)",
              padding: "4px 8px",
              background: "#FFF",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => UseShow(true)}
          />
        </ArrowContainer>
      </ProductContainer>
      {show && (
        <Cover>
          <CoverText>
            انا كمؤسس و جميع من اختار للعمل معي لدنيا خبرة واسعه في مجال هندسة
            التطبيقات لكن آوريجين هو عمل جديد و يشرفنا ان تكون من اوائل شركاء
            النجاح
          </CoverText>
          <ClouseCover onClick={() => UseShow(false)}>اغلاق</ClouseCover>
        </Cover>
      )}
    </Container>
  );
}
