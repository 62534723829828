import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import {
  ArrowDown,
  BTN,
  CallToActionContainer,
  EmptyCircle,
  FillCircle,
  HeroSecionContainer,
  HomePageLogo,
  MainSellingProp,
  ReviewText,
  ReviewTextContainer,
  SecondarySellingProp,
  StartIcons,
  TextAndReviewContainer,
  TextContainer,
} from "./HeroStyle";
import Header from "../../General/Header/Header";

export default function HeroSecion() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <HeroSecionContainer id="hero-section">
      <Header />
      <TextAndReviewContainer>
        <TextContainer>
          <MainSellingProp variant="h1">
            اوريجين، تصنع موقع إلكتروني احترافي، لمساعدة المؤسسات الناشئة و
            المواقع الشخصية على الظهور المميز، كسب الثقة و زيادة قاعدة العملاء
          </MainSellingProp>
          <SecondarySellingProp variant="h2">
            . نهتم بالجودة، الوقت و السعر
          </SecondarySellingProp>
        </TextContainer>

        <CallToActionContainer>
          <ReviewTextContainer>
            <ReviewText variant="h3">أكثر من 10+ مواقع</ReviewText>
            <StartIcons src={`/assets/StartsIcon.svg`} />
          </ReviewTextContainer>
          <BTN href="#contact-us" variant="outlined">
            اصنع موقعك الآن
          </BTN>
        </CallToActionContainer>
      </TextAndReviewContainer>

      {/* the Shape section */}
      <FillCircle src="/assets/Ellipse.svg" />
      <EmptyCircle src="/assets/HomePageEmptyCirlcle.svg" />
      {matches ? (
        <HomePageLogo src="/assets/homepagelogov1.svg" />
      ) : (
        <HomePageLogo src="/assets/HomePageLogo4Phone.svg" />
      )}
      <ArrowDown src={`/assets/arrowDown.svg`} />
    </HeroSecionContainer>
  );
}
