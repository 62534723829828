import { Box, styled } from "@mui/material";

export const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignContent: "center",
  justifyContent: "end",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "start",
  },
}));

export const BTNContainer = styled(Box)(({ theme }) => ({
  padding: "27px 0px 0 70px",

  [theme.breakpoints.up("md")]: {
    padding: "17px 0px 0 126px",
  },
}));

export const BurgerIcons = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
}));
