import { createTheme } from "@mui/material";

export const Colors = {
  primary: "#1C4324",
  secondary: "#E6EFE8",

  white: "#FFF",
  black: "#1C4324",
};

export const FontsFamily = {
  primary: "Cairo, sans-serif;",
  secondary: "Tajawal, sans-serif",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: "250px",
          borderRadius: "100px 0 0 0px",
          borderLeft: `1px solid ${Colors.primary}`,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontFamily: FontsFamily.primary,
        },
      },
    },
  },
});

export default theme;
