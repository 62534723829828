import React from "react";
import Header from "../components/General/Header/Header";
import HeroSecion from "../components/Home/HeroSection/HeroSecion";
import BoiltsPoints from "../components/Home/BolitsPoints/BoiltsPoints";
import Products from "../components/Home/Products/Products";
import SocialProof from "../components/Home/SocialProof/SocialProof";
import CTAForm from "../components/Home/CTAForm/CTAAndFAQForm";
import Footer from "../components/General/Footer/Footer";

export default function index() {
  return (
    <div>
      {/* <Header /> */}
      <HeroSecion />
      <BoiltsPoints />
      <Products />
      <SocialProof />
      <CTAForm />
      <Footer />
    </div>
  );
}
