import { Box, Typography, styled } from "@mui/material";
import { Colors, FontsFamily } from "../../General/theme";

export const Container = styled(Box)(({ theme }) => ({
  height: "982px",
  position: "relative",

  [theme.breakpoints.up("sm")]: {
    height: "597px",
  },
}));

export const BolitsPointContainer = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: "56px",
  gap: "72px",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "baseline",
    gap: "2px",
    padding: "0 8px",
    marginTop: "72px",
  },

  [theme.breakpoints.up("md")]: {
    padding: "0 96px",
    gap: "32px",
  },
}));

export const ElementContainer = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  [theme.breakpoints.up("md")]: {},
}));

export const BolitsPointIllustration = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  height: "93px",
  marginBottom: "16px",

  [theme.breakpoints.up("md")]: {
    height: "147px",
  },
}));

export const BolitsPointTitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.primary,
  textAlign: "center",
  fontSize: "12px",
  width: "40%",
  marginBottom: "8px",

  [theme.breakpoints.up("sm")]: {
    width: "100%",
    height: "40px",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
    height: "60px",
  },
}));

export const BolitsPointSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.secondary,
  fontSize: "12px",
  width: "45%",
  textAlign: "center",

  [theme.breakpoints.up("sm")]: {
    width: "100%",
    height: "40px",
  },

  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
}));

export const ShapeIllustration1 = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  position: "absolute",
  top: 150,
  right: 0,
  zIndex: -1,

  [theme.breakpoints.up("sm")]: {
    height: "0",
  },
}));
export const ShapeIllustration2 = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  position: "absolute",
  top: 450,
  left: 0,
  zIndex: -1,

  [theme.breakpoints.up("sm")]: {
    height: "0",
  },
}));
export const ShapeIllustration3 = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  position: "absolute",
  bottom: 30,
  right: 0,
  zIndex: -1,

  [theme.breakpoints.up("sm")]: {
    height: "0",
  },
}));

export const ShapeIllustration = styled("img")(({ src, theme }) => ({
  height: "0",

  [theme.breakpoints.up("sm")]: {
    src: `url(${src})`,
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: -1,
    height: "260px",
  },
  [theme.breakpoints.up("md")]: {
    height: "0",
  },
}));
