import React from "react";
import { BTNContainer, BurgerIcons, HeaderContainer } from "./HeaderStyle";
import { useMediaQuery, useTheme } from "@mui/material";
import { BTN, SecondaryBTN } from "../../Home/HeroSection/HeroStyle";
import { useUIContext } from "../../../context";

export default function Header() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { toggleDrawer } = useUIContext();
  return (
    <HeaderContainer>
      {matches ? (
        <BTNContainer>
          <BTN href="#contact-us" variant="outlined">
            اصنع موقعك الآن
          </BTN>
          <SecondaryBTN href="#bolitPoints">لماذا آوريجين</SecondaryBTN>
        </BTNContainer>
      ) : (
        <BurgerIcons
          onClick={toggleDrawer("right", true)}
          src="assets/BurgerMenu.svg"
          sx={{ padding: "32px 35px 0 0", cursor: "pointer" }}
        />
      )}
    </HeaderContainer>
  );
}
