import { Box, Typography, styled } from "@mui/material";
import { Colors, FontsFamily } from "../theme";

export const RecivedEmailContainer = styled(Box)(({ theme }) => ({
  padding: "0 16px",
  //   marginTop: "20vh",
  [theme.breakpoints.up("sm")]: {
    padding: "0 90px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "0 150px",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "0 250px",
  },
}));

export const RecivedBox = styled(Box)(({ theme }) => ({
  border: `2px solid ${Colors.primary}`,
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "32px",
  padding: "32px",
  [theme.breakpoints.up("sm")]: {
    padding: "40px",
    gap: "40px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "56px",
    gap: "48px",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "64px",
    gap: "40px",
  },
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.primary,
  textAlign: "center",
}));
