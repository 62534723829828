import { Box, Button, Typography, styled } from "@mui/material";
import { Colors } from "../../General/theme";

export const HeroSecionContainer = styled(Box)(({ theme }) => ({
  height: "422px",
  position: "relative",
  display: "flex",
  alignItems: "end",
  gap: "140px",
  flexDirection: "column",

  [theme.breakpoints.up("sm")]: {
    height: "597px",
    gap: "163px",
  },
  [theme.breakpoints.up("md")]: {
    height: "832px",
    gap: "230px",
  },
}));
export const TextAndReviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
  padding: "0 16px ",
  [theme.breakpoints.up("sm")]: {
    padding: "0 32px",
    gap: "0px",
  },
  [theme.breakpoints.up("md")]: {
    gap: "48px",
    padding: "0 24px",
  },
}));
export const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
  flexDirection: "column",
}));

export const MainSellingProp = styled(Typography)(({ theme }) => ({
  fontFamily: "Cairo, sans-serif",
  fontSize: "12px",
  textAlign: "end",
  maxWidth: "50%",
  lineHeight: "1.5",
  marginBottom: "8px",

  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    marginBottom: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    marginBottom: "16px",
  },
}));
export const SecondarySellingProp = styled(Typography)(({ theme }) => ({
  fontFamily: "Tajawal, sans-serif",
  fontSize: "12px",
  marginBottom: "38px",

  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
    marginBottom: "120px",
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: "40px",
  },
}));

export const CallToActionContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "32px",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "row-reverse",
    justifyContent: "end",
    gap: "5%",
  },
  [theme.breakpoints.up("md")]: {
    gap: "8px",
  },
}));

export const ReviewTextContainer = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "2px",

  [theme.breakpoints.up("sm")]: {
    gap: "4px",
  },
}));
export const ReviewText = styled(Typography)(({ theme }) => ({
  fontFamily: "Cairo, sans-serif",
  fontSize: "8px",

  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
}));

export const StartIcons = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  height: "10px",

  [theme.breakpoints.up("sm")]: {
    height: "14px",
  },
  [theme.breakpoints.up("md")]: {
    height: "16px",
  },
}));

export const BTN = styled(Button)(({ theme }) => ({
  fontFamily: "Cairo, sans-serif",
  backgroundColor: Colors.primary,
  color: Colors.secondary,
  borderRadius: "32px",
  padding: "8px 16px",
  fontSize: "8px",
  [theme.breakpoints.up("sm")]: {
    padding: "8px 24px",
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
}));
export const SecondaryBTN = styled(Button)(({ theme }) => ({
  fontFamily: "Cairo, sans-serif",
  color: Colors.primary,
  borderRadius: "32px",
  padding: "8px 16px",
  fontSize: "8px",
  [theme.breakpoints.up("sm")]: {
    padding: "8px 24px",
    fontSize: "12px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
}));

export const FillCircle = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  height: "47px",
  position: "absolute",
  top: "0",
  // right: "-7px",
  zIndex: -1,

  [theme.breakpoints.up("sm")]: {
    height: "67px",
    top: "139px",
    // right: "-24px",
  },
  [theme.breakpoints.up("md")]: {
    height: "86px",
    top: "90px",
    // right: "-27px",
  },
}));
export const EmptyCircle = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  height: "132px",
  position: "absolute",
  top: "156px",
  right: "110px",
  zIndex: -1,

  [theme.breakpoints.up("sm")]: {
    height: "213px",
    top: "228px",
    right: "287px",
  },
  [theme.breakpoints.up("md")]: {
    height: "291px",
    top: "226px",
    right: "386px",
  },
}));
export const HomePageLogo = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  height: "280px",
  position: "absolute",
  top: "0px",
  left: "0px",
  zIndex: -1,

  [theme.breakpoints.up("sm")]: {
    height: "633px",
    top: "77px",
    left: "0px",
  },
  [theme.breakpoints.up("md")]: {
    height: "1060px",
    top: "0px",
  },
}));
export const ArrowDown = styled("img")(({ src, theme }) => ({
  height: "0px",

  [theme.breakpoints.up("md")]: {
    src: `url(${src})`,
    height: "280px",
    position: "absolute",
    bottom: "0px",
    right: "325px",
    zIndex: -5,
    height: "250px",
  },
}));
