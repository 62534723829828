import React, { useState } from "react";
import { Carousle } from "../../../animation/Carousle/Carousle";
import { Box } from "@mui/material";
import {
  ClouseCover,
  Cover,
  CoverText,
  SectionSubtitle,
  SectionTitle,
} from "../../General/GeneralElementsStyle";
import {
  SocialProofContainer,
  SocialProofDate,
  SocialProofElementBox,
  SocialProofIcons,
  SocialProofImg,
  SocialProofText,
} from "./SocialProofStyle";

export default function SocialProof() {
  const [show, UseShow] = useState(true);
  const commentsArray = [
    {
      img: "/assets/ourCustomer/f1.png",
      date: "02/08/2023",
      comment:
        "It was much better than I imaged, they delivered on their promise 100%",
    },
    {
      img: "/assets/ourCustomer/f2.png",
      date: "02/08/2023",
      comment:
        "It was much better than I imaged, they delivered on their promise 100%",
    },
    {
      img: "/assets/ourCustomer/f3.png",
      date: "02/08/2023",
      comment:
        "It was much better than I imaged, they delivered on their promise 100%",
    },
  ];

  const displayElements = commentsArray.map((el, index) => (
    <SocialProofElementBox key={index}>
      <SocialProofImg src={el.img} />
      <SocialProofDate>{el.date}</SocialProofDate>
      <SocialProofIcons src="/assets/StartsIcon.svg" />
      <SocialProofText>{el.comment}</SocialProofText>
    </SocialProofElementBox>
  ));

  return (
    <Box sx={{ position: "relative" }} id={"social-proof"}>
      <SectionTitle> .. عملاؤنا</SectionTitle>
      <SectionSubtitle>: ابرز عملاؤنا و ابرز التعليقات</SectionSubtitle>
      <Carousle />
      <SocialProofContainer>{displayElements}</SocialProofContainer>

      {show && (
        <Cover>
          <CoverText>
            جميع ماهو موجود فقط لاظهار شكل العمل و يشرفنا ان نضع تعليقك و شعار
            شركتك بعد التعامل و اتمام العمل
          </CoverText>
          <ClouseCover onClick={() => UseShow(false)}>اغلاق</ClouseCover>
        </Cover>
      )}
    </Box>
  );
}
