import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  CTAAndFAQForm,
  CTAFormContainder,
  CTANameAndLastNameContainer,
  Title,
  InputEmail,
  InputLastName,
  InputName,
  InputPhoneNumber,
  Sumite,
  WhatAppBTN,
  FAQContainer,
  AccordionEl,
  AccordionSummaryEl,
  AccordionDetailsEl,
  AccordionElContainer,
  ShapeIllustaration,
  InputMessage,
} from "./CTAAndFAQFormStyle";
import { Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FontsFamily } from "../../General/theme";
import { ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function CTAAndFAQFormStyle() {
  const FAQArray = [
    {
      question: "كم المدة التي تستغرق لبناء الموقع",
      answer: "يختلف الوقت حسب نوع الموقع و حجمة, قالمتوسط من اسبوعين الى شهر",
    },
    {
      question: "ماهي خدمة العملاء المجانية",
      answer:
        " يتم التعديل على بعض من اجزاء الموقع البسيطة بشكل مجاني للعميل حلال الشهر الذي يعقب اطلاق الموقع",
    },
  ];
  const displayAccordion = FAQArray.map((el, index) => (
    <AccordionElContainer key={index}>
      <AccordionEl>
        <AccordionSummaryEl expandIcon={<ExpandMore />}>
          {el.question}
        </AccordionSummaryEl>
        <AccordionDetailsEl>{el.answer}</AccordionDetailsEl>
      </AccordionEl>
    </AccordionElContainer>
  ));

  const [disAble, setDisAble] = useState(false);
  let navigate = useNavigate();

  function submitEmail(e) {
    e.preventDefault();
    setDisAble(true);

    navigate("#hero-section");
    emailjs
      .sendForm(
        "service_059pr4l",
        "template_g2d546s",
        e.target,
        process.env.EMAIL_BUPLIC_KEY
      )
      .then((res) => {
        if (res.status === 200) {
          navigate("/recived");
        }
        setDisAble(true);
      })
      .catch((error) => console.log(error));
  }

  const [directionOfWriting, setDirectionOfWriting] = useState("ltr");

  function isLtr(e) {
    const string = e.target.value;
    const getFirstLitter = `${string.slice(0, 1)}`;
    const check = getFirstLitter.match(/[a-z]+|^[\#\w]+$/gi) !== null;
    console.log(check);
    check ? setDirectionOfWriting("ltr") : setDirectionOfWriting("rtl");
  }

  console.log(directionOfWriting);

  return (
    <CTAAndFAQForm id="contact-us">
      <form onSubmit={submitEmail}>
        <CTAFormContainder>
          <Title>اصنع موقعك الآن</Title>
          <CTANameAndLastNameContainer>
            <InputName
              key={1}
              onChange={isLtr}
              dir={directionOfWriting}
              required
              label="الاسم الاول"
              name="firstName"
            />
            <InputLastName
              onChange={isLtr}
              dir={directionOfWriting}
              label="الاسم الاخير"
              name="lastName"
            />
          </CTANameAndLastNameContainer>
          <InputPhoneNumber
            key={2}
            required
            onChange={isLtr}
            dir={directionOfWriting}
            label="رقم الهاتف"
            name="phone"
          />
          <InputEmail
            key={3}
            required
            lang="en"
            label="البريد الالكتروني"
            name="userEmail"
          />
          <InputMessage
            key={4}
            onChange={isLtr}
            dir={directionOfWriting}
            label="الاستفسار / الطلب"
            name="message"
          />
          <Sumite disabled={disAble} type="submit">
            آحجز مكالمة مجانية
          </Sumite>
        </CTAFormContainder>
      </form>
      <Typography sx={{ fontFamily: FontsFamily.secondary }}>آو</Typography>
      <WhatAppBTN
        target="_blank"
        variant="contained"
        href="https://wa.me/+9660576638198"
      >
        <WhatsAppIcon sx={{ margin: "0 8px" }} />
        تواصل عن طريق الواتس آب
      </WhatAppBTN>

      {/* FAQ Section */}
      <FAQContainer>
        <Title sx={{ marginBottom: "24px" }}>أسئلة متكررة</Title>
        {displayAccordion}
      </FAQContainer>

      <ShapeIllustaration src="/assets/shape5.svg" />
    </CTAAndFAQForm>
  );
}
