import React from "react";
import { RecivedBox, RecivedEmailContainer, Text } from "./RecivedEmailStyle";
import { BTN } from "../../Home/HeroSection/HeroStyle";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function RecivedEmail() {
  let navigate = useNavigate();
  return (
    <RecivedEmailContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: "50px",
        }}
      >
        <img src="/favicon.ico" width="150px" />
        <Text sx={{ textAlign: "start", marginTop: "-24px" }}>Origin Go</Text>
      </Box>
      <RecivedBox>
        <Text> شكرا لك لتواصلك معنا، سيتم الرد عليك في اقرب وقت</Text>
        <BTN onClick={() => navigate("/")} variant="contained">
          العودة لبداية الصفحة
        </BTN>
      </RecivedBox>
    </RecivedEmailContainer>
  );
}
