import React from "react";
import RecivedEmail from "../components/General/RecivedEmail/RecivedEmail";

export default function RecivedPage() {
  return (
    <div>
      <RecivedEmail />
    </div>
  );
}
