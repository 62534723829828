import { Box, Typography, styled } from "@mui/material";
import { FontsFamily } from "../../General/theme";

export const SocialProofContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "40px",
  padding: "0 100px 40px",

  [theme.breakpoints.up("sm")]: {
    padding: "0 32px 40px",
    flexDirection: "row",
  },
  [theme.breakpoints.up("md")]: {
    padding: "0 56px 40px",
    gap: "56px",

    flexDirection: "row",
  },
}));

export const SocialProofElementBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "8px",
  width: "100%",
  textAlign: "center",
}));

export const SocialProofImg = styled("img")(({ src, theme }) => ({
  src: `url${src}`,
}));

export const SocialProofDate = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.primary,
  fontSize: "16px",
}));

export const SocialProofIcons = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  height: "13px",
  [theme.breakpoints.up("md")]: {
    height: "16px",
  },
}));

export const SocialProofText = styled(Typography)(({ theme }) => ({
  fontFamily: FontsFamily.primary,
  fontSize: "12px",

  [theme.breakpoints.up("md")]: {
    fontSize: "16px",
  },
}));
